import styled from 'styled-components';
import { breakpointDown } from '../../styles/components/MediaQueries';
import colors from '../../styles/config/colors';

export const StyledHero = styled.section`
  color: ${colors.white};
  text-align: center;
  padding: 12rem 1rem 14rem 1rem;
  background-color: ${colors.brand};
  background-image: url("header.jpg");
  background-size: cover;
`

export const HeroName = styled.h1`
  color: ${colors.white};
  margin-top: 3rem;
  font-weight: 100;
  margin-bottom: 0;
  line-height: 1.1;
  font-size: 4rem;
`

export const HeroJob = styled.h2`
  color: rgba(255, 255, 255, 0.8);
  font-size: 6rem;
  word-break: break-word;
  text-transform: uppercase;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 700;
  ${(props) => breakpointDown('sm')`
    font-size: 4rem;
  `}
`

export const HeroFor = styled.h3`
  color: ${colors.white};
  font-weight: 100;
  margin: 0 auto;
  line-height: 1.1;
  font-size: 4rem;
`

export const HeroSociety = styled.strong`
  font-weight: 700;
  text-transform: uppercase;
  background: linear-gradient(48deg, #5100BA 0%, #F000E0 50%, #00FFFF 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke: 1px rgba(${colors.white}, .5);
`
