import { Link } from 'react-router-dom';
import styled from 'styled-components'
import colors from '../../styles/config/colors';

const navbar = {
  height: 80,
  itemHeight: 40
}

const isSticky = (sticky: boolean) => {
  if (sticky) {
    return `
      min-height: ${navbar.height / 2}px;
      
      ${NavbarBrand} {
        img {
          height: ${(navbar.height / 2) - 10}px;
        }
      }
      
      ${NavbarItem} {
         color: rgba(255, 255, 255, 0.8);
      }
      
      ${NavBarBurgerButton} svg {
        width: 24px;
        height: 24px;
      }
    `
  }

  return `
    min-height: ${navbar.height}px;
    background: rgba(0, 0, 0, 0.1);
  `
}

type StyledNavbarProps = {
  sticky: boolean;
}
export const StyledNavbar = styled.nav<StyledNavbarProps>`
  background: ${colors.brand};
  color: ${colors.white};
  display: flex;
  width: 100%;
  transition: all 0.4s ease;
  border: 1px solid rgba(255, 255, 255, 0.15);
  box-shadow: 0 0 6px 0px rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  z-index: 2;
  padding: 0.5rem;
  
  ${({sticky}) => isSticky(sticky)};
`

export const NavbarLeft = styled.div`
  display: flex;
  align-items: center;
`

export const NavbarRight = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
  flex-wrap: wrap;
`

export const NavbarBrand = styled(Link)`
  display: flex;
  align-items: center;

  img {
    height: ${navbar.height - 10}px;
    transition: all 0.35s ease;
  }
`
type NavBarNavProps = {
  position?: 'left' | 'right';
}
export const NavbarNav = styled.ul<NavBarNavProps>`
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  align-items: center;
  ${({position}) => position === 'left' ? 'margin-right: auto;' : 'margin-left: auto;'}
`
NavbarNav.defaultProps = {
  position: 'right'
}

export const NavbarItem = styled.li`
  text-transform: uppercase;
  font-weight: 500;

  a {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: none;
    padding: 0 1.6rem;
  }

  a:hover {
    color: ${colors.white};
  }

  a:active,
  a.active {
    color: ${colors.white};
  }
`

export const NavBarBurgerButton = styled.button`
  height: 3.6rem;
  background-color: transparent;
  border: none;
  margin-left: auto;
  align-self: center;
  &:focus {
    outline: none;
  }
  
  svg {
    vertical-align: middle;
    color: ${colors.white};
    width: ${navbar.height / 2}px;
    height: ${navbar.height / 2}px;
    transition: all 0.4s ease;
  }
`

type NavBarBurgerMenuProps = {
  className?: string;
}
export const NavBarBurgerMenu = styled(NavbarNav)<NavBarBurgerMenuProps>`
  flex-direction: column;
  align-items: flex-end;
  height: 0;
  width: 100%;
  overflow: hidden;
  transition: all 0.4s ease;

  &.opened {
    padding-top: 2rem;
    height: auto;
  }

  ${NavbarItem} {
    height: ${navbar.itemHeight}px;
  }
`
