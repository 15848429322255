import UisBars from '@iconscout/react-unicons/icons/uil-bars'
import UilTimes from '@iconscout/react-unicons/icons/uil-times'
import React, { FunctionComponent, useEffect, useState } from "react"
import { Default, Mobile } from '../../styles/components/MediaQueries';
import { FlexContainer } from '../../templates/Container.styled';

import { NavbarBrand, NavBarBurgerButton, NavBarBurgerMenu, NavbarItem, NavbarNav, StyledNavbar } from './Navbar.styled'

type NavbarProps = {
  scrollY?: number;
}

const Navbar: FunctionComponent<NavbarProps> = ({scrollY = 60}) => {

  const [sticky, setSticky] = useState(false);
  const [opened, setOpened] = useState(false);

  const resizeOnScroll = (event: Event) => {
    setOpened(false);
    if (window.scrollY > scrollY) {
      setSticky(true)
    } else if (window.scrollY <= scrollY) {
      setSticky(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', resizeOnScroll)
    return () => {
      window.removeEventListener('scroll', resizeOnScroll)
    }
  })

  const menuItems = [
    <NavbarItem><a href="#services">Services</a></NavbarItem>,
    <NavbarItem><a href="#skills">Compétences</a></NavbarItem>,
    <NavbarItem><a href="#portfolio">Portfolio</a></NavbarItem>,
    <NavbarItem><a href="#apropos">à propos</a></NavbarItem>,
    <NavbarItem><a href="#contact">Contact</a></NavbarItem>
  ];

  const classNameOpened = opened ? 'opened' : undefined;

  return (
    <StyledNavbar sticky={sticky}>
      <FlexContainer>
        <NavbarBrand to="/"><img src="logo/logo-72x72.png" alt=""/></NavbarBrand>
        <Default>
          <NavbarNav>{menuItems}</NavbarNav>
        </Default>
        <Mobile>
          <NavBarBurgerButton onClick={() => setOpened(!opened)}>
            {!opened ? <UisBars/> : <UilTimes/>}
          </NavBarBurgerButton>
          <NavBarBurgerMenu className={classNameOpened}>{menuItems}</NavBarBurgerMenu>
        </Mobile>
      </FlexContainer>
    </StyledNavbar>
  )
}

export default Navbar
