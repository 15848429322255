import styled from 'styled-components';
import colors from '../../styles/config/colors';
import spacings from '../../styles/config/spacings';


export const StyledPortfolio = styled.section`
  padding: 6rem 0;
`

export const PortfolioCardText = styled.div`
  padding: ${spacings.spacerSmallPlus};
  h3 {
    color: ${colors.brand};
  }

  p {
    color: ${colors.gray700};
  }
`

export const PortfolioCard = styled.div`
  background: rgba(${colors.black}, 0.05);
  transition: all 0.4s ease;
  
  &:hover {
    background: ${colors.brand};

    ${PortfolioCardText} {
      h3 {
        color: ${colors.white};
      }

      p {
        color: ${colors.gray500};
      }
    }
  }
`
