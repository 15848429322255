import styled from 'styled-components'
import colors from '../../styles/config/colors';

const progressBarVariables = {
  height: '3rem'
}

export const StyledProgressBar = styled.li`
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  position: relative;
  height: ${progressBarVariables.height};
  margin-bottom: 2rem;
`

type ProgressBarPercentProps = {
  width: number
}
export const ProgressBarPercent = styled.div<ProgressBarPercentProps>`
  height: 100%;
  width: ${({width}) => width}%;
  transition: all 0.4s ease;
  background-color: ${colors.brand};
`

export const ProgressBarText = styled.div`
  height: 100%;
  width: 140px;
  position: absolute;
  text-transform: uppercase;
  color: ${colors.white};
  top: 0;
  left: 0;
  line-height: ${progressBarVariables.height};
  padding: 0 1rem;
  background-color: rgba(255, 255, 255, .4);
  font-size: 1.2rem;
  font-weight: 700;
`
