import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

type ParallaxProps = {
  background: string;
}

export const StyledParallalax = styled.div<ParallaxProps>`
  background-image: url(${props => props.background});
  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const ParallaxHolder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`

const Parallax: FunctionComponent<ParallaxProps> = ({background}) => (
  <ParallaxHolder>
    <StyledParallalax background={background}/>
  </ParallaxHolder>
)

export default Parallax;
