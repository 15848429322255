import styled from 'styled-components';

export const StyledApropos = styled.section`
  padding: 6rem 0;
  
  h3 {
    font-size: 26px;
    margin-bottom: 2rem;
  }
`

