import React, { FunctionComponent } from "react"

import { StyledServicesItem, ServicesItemTitle, ServicesItemDescription } from './Services-Item.styled'

type ServiceItemProps = {
  title: string;
  description: string;
  icon: any;
}

const ServiceItem: FunctionComponent<ServiceItemProps> = ({title, description, icon}) => (
  <StyledServicesItem>
    {icon()}
    <ServicesItemTitle>{title}</ServicesItemTitle>
    <ServicesItemDescription>{description}</ServicesItemDescription>
  </StyledServicesItem>
)

export default ServiceItem
