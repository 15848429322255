import React, { FunctionComponent } from "react"
import Footer from '../molecules/Footer';

import Header from "../molecules/Header"

const Layout: FunctionComponent = ({children}) => (
  <>
    <Header/>
    <main>{children}</main>
    <Footer/>
  </>
)


export default Layout
