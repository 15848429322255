import React, { FunctionComponent, useRef } from "react"
import { ProgressBarPercent, ProgressBarText, StyledProgressBar } from './ProgressBar.styled';

type ProgressBarProps = {
  value: number;
  title?: string;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({value, title}) => {
  return (
    <StyledProgressBar>
      <ProgressBarPercent width={value}/>
      {title ? (
        <ProgressBarText>{title}</ProgressBarText>
      ) : null}
    </StyledProgressBar>
  )

}

export default ProgressBar
