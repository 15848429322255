import styled from 'styled-components'
import colors from '../../../styles/config/colors';

export const ServicesItemTitle = styled.h4`
  color: rgba(49, 4, 113, .6);
  transition: all 0.4s ease;
`

export const ServicesItemDescription = styled.p`
    color: ${colors.gray500};
    transition: all 0.4s ease;
`

export const StyledServicesItem = styled.div`
  svg {
    fill: rgba(49, 4, 113, .6);
    transition: all 0.4s ease;
  }
  
  &:hover {
    svg {
      fill: ${colors.brand};
    }

    ${ServicesItemTitle} {
      color: ${colors.brand};
    }

    ${ServicesItemDescription} {
      color: ${colors.gray600};
    }
  }
`
