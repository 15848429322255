const spacings = {
  spacerTiny: '0.5rem',
  spacerTinyPlus: '0.7rem',
  spacerSmall: '1rem',
  spacerSmallPlus: '1.5rem',
  spacerMedium: '2rem',
  spacerMediumPlus: '3rem',
  spacerLarge: '4rem',
  spacerLargePlus: '6rem',
  spacerExtraLarge: '8rem',
  spacerExtraLargePlus: '12rem',
  spacerUltraLarge: '16rem',
  spacerUltraLargePlus: '20rem',
}

export default spacings;
