import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components'
import { StandardProperties } from 'csstype';
import { Breakpoint, BreakpointKey, breakpoints, breakpointUp } from '../styles/components/MediaQueries';

type SpanAndOffset = {
  span: number;
  offset: number;
}

type SizeAndOffsetProps = {
  [B in keyof Breakpoint]?: number | SpanAndOffset;
}

interface ColProps extends SizeAndOffsetProps {
  first?: boolean;
  last?: boolean;
  order?: number;
  style?: StandardProperties;
  className?: string;
}

const BREAKPOINTS =  Object.keys(breakpoints);

const colResponsiveStyle = css<ColProps>`
  flex: 0 0 100%;
  max-width: 100%;

  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  
  ${({theme, ...props}) => css`
    ${(Object.keys(props) as BreakpointKey[])
            .filter((breakpoint) => BREAKPOINTS.includes(breakpoint))
            .sort((k1, k2) => BREAKPOINTS.indexOf(k1) - BREAKPOINTS.indexOf(k2))
            .map((breakpoint) => {
              const span = (props[breakpoint] as SpanAndOffset).span ? (props[breakpoint] as SpanAndOffset).span : (props[breakpoint] as number);
              const offset = (props[breakpoint] as SpanAndOffset).offset ? (props[breakpoint] as SpanAndOffset).offset : null;

              return breakpointUp(breakpoint)`
                flex: 0 0 ${(100 / 12) * span}%;
                max-width: ${(100 / 12) * span}%;
          
                ${offset && css`margin-${theme.dir === 'rtl' ? 'right' : 'left'}: ${(100 / 12) * offset}%;`}
              `
            })
    }
  `}
`

const StyledCol = styled.div<ColProps>`
  ${({theme, first, last, order}) => css`
    position: relative;
    width: 100%;
    min-height: 1px;
    // padding-right: {getGridGutter(theme) / 2}px;
    // padding-left: {getGridGutter(theme) / 2}px;
    ${colResponsiveStyle}
    ${first && 'order: -1;'} 
    ${last && `order: ${12 + 1};`}
    ${order && `order: ${order};`}
  `}
`

export const Col: FunctionComponent<ColProps> = ({children, ...props}) => <StyledCol {...props}>{children}</StyledCol>
