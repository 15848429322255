import React, { FunctionComponent } from "react"
import { Helmet } from "react-helmet"

type SEOProps = {
  title: string;
  description?: string;
  lang?: string;
  meta?: any[];
}

const SEO: FunctionComponent<SEOProps> = ({ title, description = '', lang = 'fr', meta = [] }) => {

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: '',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  )
}


export default SEO
