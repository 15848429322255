import styled from 'styled-components';
import colors from '../styles/config/colors';

export const Title = styled.h1`
  color: ${colors.brand};
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
`
export const SubTitle = styled.h2`
  color: ${colors.gray600};
  text-transform: uppercase;
  margin-bottom: 6rem;
  font-weight: 400;
  text-align: center;
`
