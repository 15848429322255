import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { FunctionComponent } from "react"
import { FlexContainer } from '../../templates/Container.styled';
import { QuoteTitle, StyledQuote } from './Quote.styled';
import Parallax from '../../atoms/Parallax';

type QuoteProps = {
  background: string;
}

const Quote: FunctionComponent<QuoteProps> = ({background, children}) => (
  <StyledQuote>
    <FlexContainer>
      <QuoteTitle>
        <FontAwesomeIcon icon={faQuoteLeft}/> {children} <FontAwesomeIcon icon={faQuoteRight}/>
      </QuoteTitle>
    </FlexContainer>
    <Parallax background={background}/>
  </StyledQuote>
)

export default Quote
