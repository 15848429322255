import React, { FunctionComponent } from "react"

import { SubTitle, Title } from '../../atoms/Title.styled';
import { Col } from '../../templates/Col.styled';
import { Container, GridContainer } from '../../templates/Container.styled';
import { Row } from '../../templates/Row.styled';
import { PortfolioCard, PortfolioCardText, StyledPortfolio } from "./Portfolio.styled";

const Portfolio: FunctionComponent = () => (
  <StyledPortfolio id="portfolio">
    <Container>
      <Title>Mes projets</Title>
      <SubTitle>Ils m'ont fait confiance</SubTitle>
      <Container>
        <Row>
          <Col lg={4} md={6} sm={12} xs={12}>
            <PortfolioCard>
              <img src="portfolio/cycle-up.png"/>
              <PortfolioCardText>
                <h3>Cycle Up</h3>
                <p>Réemploi des matériaux dans le BTP (particuliers / professionnels)</p>
              </PortfolioCardText>
            </PortfolioCard>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <PortfolioCard>
              <img src="portfolio/oui.png"/>
              <PortfolioCardText>
                <h3>Oui.sncf</h3>
                <p>Vente de billets de train en ligne (particuliers / professionnels)</p>
              </PortfolioCardText>
            </PortfolioCard>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <PortfolioCard>
              <img src="portfolio/terega.png"/>
              <PortfolioCardText>
                <h3>Portail Terega</h3>
                <p>Solution de transport et stockage de gaz (professionnels)</p>
              </PortfolioCardText>
            </PortfolioCard>
          </Col>
        </Row>
      </Container>
    </Container>
  </StyledPortfolio>
)

export default Portfolio
