import UilBrush from '@iconscout/react-unicons/icons/uil-brush-alt'
import UilCloudCheck from '@iconscout/react-unicons/icons/uil-cloud-check'
import UilConstructor from '@iconscout/react-unicons/icons/uil-constructor'
import UilCreateDashboard from '@iconscout/react-unicons/icons/uil-create-dashboard'
import UilFileCheck from '@iconscout/react-unicons/icons/uil-file-check-alt'
import UilImageResizeLandscape from '@iconscout/react-unicons/icons/uil-image-resize-landscape'
import UilProcess from '@iconscout/react-unicons/icons/uil-process'
import UilShieldCheck from '@iconscout/react-unicons/icons/uil-shield-check'
import UilWrench from '@iconscout/react-unicons/icons/uil-wrench'
import React, { FunctionComponent } from "react"
import { SubTitle, Title } from '../../atoms/Title.styled';
import { Col } from '../../templates/Col.styled';
import { Container, GridContainer } from '../../templates/Container.styled';
import { Row } from '../../templates/Row.styled';
import ServiceItem from "./Item"
import { ServicesBox, StyledServices } from './Services.styled';

const Services: FunctionComponent = () => (
  <StyledServices id={'services'}>
    <Container>
      <ServicesBox>
        <Title>Mes services</Title>
        <SubTitle>Pour répondre à vos besoins</SubTitle>
        <Container>
          <Row>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Cloud Computing (AWS)"
                           description="Réalisation d'applications Web dans le cloud. Lambda, API Gateway, RDS, ..."
                           icon={() => (<UilCloudCheck size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Architecture"
                           description="Construction de vos systémes d'informations sur une base robuste."
                           icon={() => (<UilConstructor size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Audit & Qualité"
                           description="Audit du code et recommandations en fonction des normes de développement."
                           icon={() => (<UilFileCheck size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Sécurité & Confidentialité"
                           description="Respect des normes de sécurité en terme de développement."
                           icon={() => (<UilShieldCheck size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Intégration Web & Webdesign"
                           description="Réalisation de wireframe et maquette. Intégration selon les normes du Web."
                           icon={() => (<UilBrush size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Responsive Design"
                           description="Compatibité tous navigateurs et devices (mobile et tablette)"
                           icon={() => (<UilImageResizeLandscape size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Développement Spécifique"
                           description="Des languages et outils adaptés à vos besoins."
                           icon={() => (<UilCreateDashboard size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="Méthodologie Agile"
                           description="SCRUM ou Kanban pour développer vos projets de façon méthodique."
                           icon={() => (<UilProcess size="100"/>)}/>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12}>
              <ServiceItem title="DevOps"
                           description="Mise en place et utilisation d'outils pour le CI/CD."
                           icon={() => (<UilWrench size="100"/>)}/>
            </Col>
          </Row>
        </Container>
      </ServicesBox>
    </Container>
  </StyledServices>
)

export default Services
