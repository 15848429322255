import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components'
import {StandardProperties} from 'csstype';

type FlexProps = 'reverse' | 'center' | 'start' | 'end' | 'between' | 'around' | 'top' | 'middle' | 'bottom';

interface RowProps extends Partial<Record<FlexProps, boolean>> {
  style?: StandardProperties;
  className?: string;
}

const StyledRow = styled.div<RowProps>`
  display: flex;
  flex-wrap: wrap;
  
  margin-left: -1rem;
  margin-right: -1rem;
  
  ${(props) => css`
    ${props.reverse && css`flex-direction: row-reverse;`}
    
    ${props.center && css`justify-content: center;`}
    ${props.start && css`justify-content: start;`}
    ${props.end && css`justify-content: end;`}
    ${props.between && css`justify-content: space-between;`}
    ${props.around && css`justify-content: space-around;`}
    
    ${props.top && css`align-items: start;`}
    ${props.middle && css`align-items: center;`}
    ${props.bottom && css`align-items: end;`}
  `}
`

export const Row: FunctionComponent<RowProps> = ({children, ...props}) => <StyledRow {...props}>{children}</StyledRow>
