import React, { FunctionComponent } from "react"
import { SubTitle, Title } from '../../atoms/Title.styled';
import { Col } from '../../templates/Col.styled';
import { Container } from '../../templates/Container.styled';
import { Row } from '../../templates/Row.styled';
import { StyledApropos } from './Apropos.styled';

const Portfolio: FunctionComponent = () => (
  <StyledApropos id="apropos">
    <Container>
      <Title>A Propos</Title>
      <SubTitle>Je suis développeur web fullstack</SubTitle>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <h3 className="mbm">Un développeur passionné</h3>
            <p>Mon parcours commence en 2010 où j'intègre une grande entreprise française de l'automobile. J'y apprends beaucoup, notamment sur les méthodes de développement. Mais c'est en 2015 que mon aventure commence.</p>
            <p>C'est cette année où je décide de devenir prestataire dans une SSII, un éventail de possibilités s'offre alors à moi. Projet et domaine divers, dernières technologies, taille d'équipe variée, j'en apprends toujours plus et j'aime ça.</p>
            <p>Fin 2019, après avoir acquis assez d'expérience, je décide de devenir développeur indépendant, Clever Code prend vie.</p>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <h3 className="mbm">Bonjour, moi c'est Philippe</h3>
            <p>
              J'ai travaillé sur des sujets à fort challenge
              autour des technologies Java/JEE (Web/mobile) et Front dans
              divers domaines : Industrie automobile / Bâtiment / Transport
              ferroviaire, Assurance et Energie.
            </p>
            <p>
              Fort de 10 ans d'expérience, mon parcours dans le domaine de
              l'Informatique applicative, m'a permis d'acquérir une forte
              compétence dans la conception, le développement, l’innovation
              et l’écoute du client.
            </p>
            <p>
              Chez mes clients, j’ai
              pu développer mon appétence pour l’agilité, l’automatisation
              (DevOps) et le webdesign.
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  </StyledApropos>
)

export default Portfolio
