import styled, { css } from 'styled-components'
import { BreakpointKey, breakpoints, breakpointUp, containerSize } from '../styles/components/MediaQueries';

const width = css<{ fluid?: boolean }>`
  ${({fluid}) => !fluid && (Object.keys(breakpoints) as BreakpointKey[])
          .map((key: BreakpointKey) => breakpointUp(key)`
            max-width: ${containerSize[key]}px;
          `)}
`

interface ContainerProps {
  fluid?: boolean
}

export const Container = styled.section<ContainerProps>`
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  ${width}
`

Container.defaultProps = {
  fluid: false
}

interface FlexContainerProps extends ContainerProps {
  flexDirection?: 'row' | 'column';
  justifyContent?: 'center' | 'space-between';
}

export const FlexContainer = styled(Container)<FlexContainerProps>`
  display: flex;
  flex-wrap: wrap;
  ${({flexDirection}) => `flex-direction: ${flexDirection};`};
  ${({justifyContent}) => `justify-content: ${justifyContent};`};
`

FlexContainer.defaultProps = {
  flexDirection: 'row'
}

const hasGutter = (gutter?: number) => {
  if (gutter) {
    return `
      grid-column-gap: ${gutter}rem; 
      grid-row-gap: ${gutter}rem;
    `
  }
}

const hasNthCols = (cols?: number) => {
  if (cols) {
    return `
      grid-template-columns: repeat(${cols}, 1fr);    
    `
  }

  return `
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  `
}

interface GridContainer extends ContainerProps {
  gutter?: number;
  cols?: number;
}

export const GridContainer = styled(Container)<GridContainer>`
  display: grid;
  ${({gutter}) => hasGutter(gutter)};
  ${({cols}) => hasNthCols(cols)};
`

interface CellProps {
  column?: number | 'auto';
  row?: number | 'auto'
}

export const Cell = styled.div<CellProps>`
  grid-column: ${({column}) => `span ${column} / span ${column}`};
  grid-row: ${({row}) => row};
`

Cell.defaultProps = {
  column: 'auto',
  row: 'auto'
}
