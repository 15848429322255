import UilEnvelope from '@iconscout/react-unicons/icons/uil-envelope';
import UilMap from '@iconscout/react-unicons/icons/uil-map';
import UilMobileAndroid from '@iconscout/react-unicons/icons/uil-mobile-android';
import UilGlobe from '@iconscout/react-unicons/icons/uil-globe';
import React, { FunctionComponent } from "react"
import { ContactItem, ContactList } from '../../organims/SimpleContact/SimpleContact.styled';
import { Col } from '../../templates/Col.styled';
import { Container } from "../../templates/Container.styled"
import { Row } from '../../templates/Row.styled';
import { StyledFooter } from './Footer.styled';

const Footer: FunctionComponent<{}> = () => (
  <StyledFooter>
    <Container>
      <Row between>
        <Col lg={6} md={6} sm={8} xs={12}>
          <h3>Développeur Informatique Indépendant</h3>
          <p>
            Développeur Full-Stack, je suis à votre disposition pour répondre à tous types de projets web.
            Fort de 10 ans d'expérience dans le développement web, je réponds à vos besoins.
            Entreprise, association ou particulier, que puis-je pour vous ?
          </p>
        </Col>
        <Col lg={4} md={4} sm={6} xs={12}>
          <h3>Philippe Wronski</h3>
          <ul>
            <li>
              <UilMap/>
              <span>7 rue du dolingen <br/> 78440 Gargenville</span>
            </li>
            <li>
              <UilMobileAndroid/>
              <span>07 70 04 69 46</span>
            </li>
            <li>
              <UilEnvelope/>
              <span>contact@clever-code.fr</span>
            </li>
            <li>
              <UilGlobe/>
              <span>clever-code.fr</span>
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  </StyledFooter>
)

export default Footer
