import React, { FunctionComponent } from "react"
import { Container } from '../../templates/Container.styled';
import { HeroFor, HeroJob, HeroName, HeroSociety, StyledHero } from './Hero.styled'

const Hero: FunctionComponent = () => (
  <StyledHero>
    <Container>
      <HeroName>Philippe Wronski</HeroName>
      <HeroJob>Développeur Full-Stack Indépendant</HeroJob>
      <HeroFor>Pour la société <HeroSociety>Clever Code</HeroSociety></HeroFor>
    </Container>
  </StyledHero>
)


export default Hero;
