import styled from 'styled-components'
import colors from '../../styles/config/colors';

export const StyledServices = styled.section`
  position: relative;
`

export const ServicesBox = styled.div`
  background-color: ${colors.white};
  box-shadow: 0 0 75px 0 rgba(69, 77, 93, 0.1);
  margin-top: -6rem;
  margin-bottom: 6rem;
  text-align: center;
  padding: 4rem 2rem;
`
