import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import StyledApp from './App.styled'
import SEO from './components/Seo/seo';
import Apropos from './organims/Apropos';
import Hero from './organims/Hero';
import Portfolio from './organims/Portfolio';
import Quote from './organims/Quote';
import Services from './organims/Services';
import SimpleContact from './organims/SimpleContact';
import Skills from './organims/Skills';
import Layout from './templates/Layout';


function App() {
  return (
    <Router>
      <StyledApp>
        <Layout>
          <SEO title="Clever Code" />
          <Hero/>
          <Services/>
          <Quote background={"techno_web.jpg"}>Technologies Web multiples</Quote>
          <Skills/>
          <Quote background={"career.jpg"}>
            Développeur Informatique depuis 2010
            <br/>
            Développeur indépendant depuis 2020
          </Quote>
          <Portfolio/>
          <Quote background={"phone_and_macbook.jpg"}>Entreprises, associations, particuliers. <br/> Que puis-je pour vous ?</Quote>
          <Apropos/>
          <Quote background={"career.jpg"}>Un développeur passionné</Quote>
          <SimpleContact/>
        </Layout>
      </StyledApp>
    </Router>
  );
}

export default App;
