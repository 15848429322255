import styled from 'styled-components';
import colors from '../../styles/config/colors';


export const StyledFooter = styled.footer`
  background-color: ${colors.brand};
  padding: 4rem 0;
  
  * {
    color: ${colors.white};
  }
  
  p, span {
    color: ${colors.gray500};
  }
  
  svg {
    fill: ${colors.gray500};
  }
  
  span {
    margin-left: 1rem;
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    display: flex;
    align-items: center;
  }
`
