import React, { FunctionComponent } from "react"
import { SubTitle, Title } from '../../atoms/Title.styled';
import ProgressBar from "../../molecules/ProgressBar"
import { Col } from '../../templates/Col.styled';
import { Container } from '../../templates/Container.styled';
import { Row } from '../../templates/Row.styled';
import { SkillsAreaTitle, SkillsDomainItem, SkillsList, StyledSkills } from './Skills.styled';


const Skills: FunctionComponent = () => (
  <StyledSkills id="skills">
    <Container>
      <Title>Mes compétences</Title>
      <SubTitle>Des compétences à votre service</SubTitle>
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <SkillsAreaTitle>Mes domaines de compétences</SkillsAreaTitle>
            <SkillsList>
              <SkillsDomainItem>
                <h4>Environnement AWS</h4>
                <p>Lambda, API Gateway, S3, RDS, CloudWatch, ...</p>
              </SkillsDomainItem>
              <SkillsDomainItem>
                <h4>Architecture</h4>
                <p>Choix des outils, languages et frameworks adaptés.</p>
              </SkillsDomainItem>
              <SkillsDomainItem>
                <h4>Audit et Qualité</h4>
                <p>Sonar Cube, esLint, tsLint, ...</p>
              </SkillsDomainItem>

              <SkillsDomainItem>
                <h4>Sécurité</h4>
                <p>OWASP, XSS, CSRF, Injection SQL, ...</p>
              </SkillsDomainItem>
              <SkillsDomainItem>
                <h4>Responsive Design</h4>
                <p>Compatibité tous navigateurs et devices (mobile et tablette)</p>
              </SkillsDomainItem>
              <SkillsDomainItem>
                <h4>Technologies Web Multiples</h4>
                <p>HTML, CSS, Javascript, Typescript, JAVA, SQL, MongoDB, ...</p>
              </SkillsDomainItem>

              <SkillsDomainItem>
                <h4>Méthodologie Agile</h4>
                <p>SCRUM et Kanban</p>
              </SkillsDomainItem>
              <SkillsDomainItem>
                <h4>Outils et OS</h4>
                <p>Windows, Linux et MacOS, IntelliJ, WebStorm, VS Code, ...</p>
              </SkillsDomainItem>
              <SkillsDomainItem>
                <h4>DevOps</h4>
                <p>CI/CD, Gitlab CI, BitBucket Pipeline, Github Action, Jenkins</p>
              </SkillsDomainItem>
            </SkillsList>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <SkillsAreaTitle>Mes compétences en développement</SkillsAreaTitle>
            <SkillsList>
              <ProgressBar value={76} title="HTML - CSS"/>
              <ProgressBar value={85} title="Typescript"/>
              <ProgressBar value={88} title="Javascript"/>
              <ProgressBar value={78} title="Java"/>
              <ProgressBar value={92} title="Spring"/>
              <ProgressBar value={92} title="SQL"/>
              <ProgressBar value={82} title="AWS"/>
              <ProgressBar value={70} title="Méthodes Agiles"/>
              <ProgressBar value={70} title="DevOps"/>
            </SkillsList>
          </Col>
        </Row>
      </Container>
    </Container>
  </StyledSkills>
)

export default Skills
