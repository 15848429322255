import React, { FunctionComponent } from "react"
import Navbar from "../Navbar"

type HeaderProps = {
  siteTitle?: string;
}

const Header: FunctionComponent<HeaderProps> = ({ siteTitle = '' }) => (
  <header>
    <Navbar/>
  </header>
)

export default Header
