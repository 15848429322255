import React, { FunctionComponent } from "react"
import { useMediaQuery } from 'react-responsive'
import { css, DefaultTheme, ThemedCssFunction } from 'styled-components'

export interface Breakpoint {
  xs : number;
  sm : number;
  md : number;
  lg : number;
  xl : number;
}
export const breakpoints: Breakpoint = {
  xs : 480,
  sm : 576,
  md : 768,
  lg : 992,
  xl : 1200,
}

export type BreakpointKey = keyof Breakpoint;

export const containerSize = {
  xs : 380,
  sm : 540,
  md : 720,
  lg : 960,
  xl : 1140,
}

const up = (key: BreakpointKey): number => breakpoints[key]+1
const down = (key: BreakpointKey): number => breakpoints[key]-1

export const breakpointUp = (key: BreakpointKey): ThemedCssFunction<DefaultTheme> => (first: any, ...interpolations: any[]) => {
  if(breakpoints[key]) {
    return css`
      @media (min-width: ${breakpoints[key]}px) {
        ${css(first, ...interpolations)}
      }
    `
  }

  return css(first, ...interpolations);
}

export const breakpointDown = (key: BreakpointKey): ThemedCssFunction<DefaultTheme> => (first: any, ...interpolations: any[]) => css`
  @media(max-width: ${breakpoints[key]}px) {
    ${css(first, ...interpolations)}
  }
`

export const breakpointBetween = (lower: BreakpointKey, upper: BreakpointKey): ThemedCssFunction<DefaultTheme> => (first: any, ...interpolations: any[]) => css`
  @media(min-width: ${breakpoints[lower]}px) and (max-width: ${down(upper)}px) {
    ${css(first, ...interpolations)}
  }
`

type MediaQueryProps = {
}

export const Desktop: FunctionComponent<MediaQueryProps> = ({children}) => {
  const isDesktop = useMediaQuery({ minWidth: breakpoints.lg })
  // We need to wrap children into fragment (see: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051#issuecomment-644954717)
  return isDesktop ? <>{children}</> : null
}

export const Tablet: FunctionComponent<MediaQueryProps> = ({ children }) => {
  const isTablet = useMediaQuery({ minWidth: breakpoints.md, maxWidth: breakpoints.lg-1 })
  return isTablet ? <>{children}</> : null
}

export const Mobile: FunctionComponent<MediaQueryProps> = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md-1 })
  return isMobile ? <>{children}</> : null
}

export const Default: FunctionComponent<MediaQueryProps> = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: breakpoints.md })
  return isNotMobile ? <>{children}</> : null
}
