import styled from 'styled-components'
import ProgressBar from '../../molecules/ProgressBar';
import colors from '../../styles/config/colors';

export const StyledSkills = styled.section`
  background-color: ${colors.white};
  color: ${colors.brand};
  padding: 6rem 1rem;
`

export const SkillsAreaTitle = styled.h3`
  font-size: 26px;
  margin-bottom: 2rem;
`

export const SkillsList = styled.ul`
  list-style: none;
  padding: 0;
`

export const SkillsDomainItem = styled.li`
  margin-left: 2.8rem;
  margin-bottom: 1rem;
  position: relative;

  &:before {  
    //content: '\\e901';
    //content: '\\e9c2';
    content: '\\e8da';
    font-family: unicons;
    font-size: 2rem;
    position: absolute;
    left: -3rem;
    line-height: 1.2;
  }
`

export const SkillsDomainTitle = styled.h6`
  color: rgba(49, 4, 113, .6);
  text-transform: uppercase;
  font-weight: 700;
`

export const SkillsDomainDescription = styled.p`
  color: ${colors.gray500};
`

export const SkillsValuesItem = styled(ProgressBar)`
  margin-bottom: 2rem;
`
