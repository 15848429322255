const purple = '#310471';
const colors = {
  brand: purple,
  white: '#fff',
  gray100: '#f8f9fa',
  gray200: '#e7e9ed',
  gray400: '#ced4da',
  gray300: '#dee2e6',
  gray500: '#acb3c2',
  gray600: '#727e96',
  gray700: '#454d5d',
  gray800: '#333',
  gray900: '#212529',
  black: '#000',
}

const rgbPurple = [49, 4, 113];
export const rgbColors = {
  brand: rgbPurple,
  white: '#fff'
}

export default colors
