import styled from 'styled-components'
import colors from '../../styles/config/colors';

type StyledQuoteProps = {
  // background: string;
}
export const StyledQuote = styled.section<StyledQuoteProps>`
  color: ${colors.white};
  text-align: center;
  padding: 8rem 1rem 8rem 1rem;
  position: relative; 
`

export const QuoteTitle = styled.h1`
  color: ${colors.white};
  font-size: 4rem;
  font-weight: 800;
  flex: 1;
  padding: 0 1rem;
`
