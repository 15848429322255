import styled from 'styled-components';
import colors from '../../styles/config/colors';

export const StyledSimpleContact = styled.section`
  padding: 6rem 0;
  
  input, textarea {
    width: 100%;
  }
  
  .map > div {
    width: 100%;
    height: 100%;
    min-height: 20rem;
  }
`

export const ContactList = styled.ul`
  list-style: none;
  padding: 0;
`

export const ContactItem = styled.li`
  margin-bottom: 3rem;
  position: relative;
  display: grid;
  grid-template:  "icon title" auto
                  "icon value" auto / 6rem auto;

  svg {
    grid-area: icon;
    color: ${colors.brand};
  }
  
  h4 {
    grid-area: title;
    text-transform: uppercase;
    font-weight: bold;
  }
  
  p {
    grid-area: value;
  }
`


