import UilEnvelope from '@iconscout/react-unicons/icons/uil-envelope'
import UilMap from '@iconscout/react-unicons/icons/uil-map'
import UilMobileAndroid from '@iconscout/react-unicons/icons/uil-mobile-android'
import GoogleMapReact from 'google-map-react';
import { GoogleApiWrapper, IProvidedProps, Map, Marker } from 'google-maps-react';
import React, { FunctionComponent } from "react"
import { SubTitle, Title } from '../../atoms/Title.styled';
import { Col } from '../../templates/Col.styled';
import { Cell, Container, GridContainer } from '../../templates/Container.styled';
import { Row } from '../../templates/Row.styled';

import { ContactItem, ContactList, StyledSimpleContact } from './SimpleContact.styled';

type CleverCodeMarkerProps = {
  lat: number;
  lng: number;
  text?: string;
}

const CleverCodeMarker: FunctionComponent<CleverCodeMarkerProps> = ({lat, lng, text}) => <img src="logo/logo-48x48.png"
                                                                                              alt={text}/>;

// @ts-ignore
const MapContainer: FunctionComponent<IProvidedProps> = ({google}) => (
  <Map google={google}
       zoom={9}
       mapTypeControl={false}
       streetViewControl={false}
       style={{width: '100%', height: '100%'}}
       containerStyle={{position: 'relative', width: '100%', height: '100%'}}
       initialCenter={{
         lat: 48.9930855,
         lng: 1.8207168
       }}
  >
    <Marker
      mapCenter={{lat: 48.9930855, lng: 1.8207168}}/>
  </Map>
);


const CleverCodeMap = GoogleApiWrapper({
  apiKey: 'AIzaSyCjbjYhGeVk5hqNByj_PsuzwsdZb5LMIb4'
})(MapContainer);

const Contact: FunctionComponent = () => (
  <StyledSimpleContact id="contact">
    <Container>
      <Title>Contactez moi</Title>
      <SubTitle></SubTitle>
      <Container>
        <Row>
          <Col lg={5} md={5} sm={12} xs={12}>
            <ContactList>
              <ContactItem>
                <UilMap size="40"/>
                <h4>Adresse</h4>
                <p>7 rue du dolingen, 78440 Gargenville</p>
              </ContactItem>
              <ContactItem>
                <UilMobileAndroid size="40"/>
                <h4>Téléphone</h4>
                <p>07 70 04 69 46</p>
              </ContactItem>
              <ContactItem>
                <UilEnvelope size="40"/>
                <h4>E-mail</h4>
                <p>contact@clever-code.fr</p>
              </ContactItem>
            </ContactList>
          </Col>
          <Col lg={7} md={7} sm={12} xs={12} className='map'>
            <CleverCodeMap/>
          </Col>
        </Row>
      </Container>
    </Container>
  </StyledSimpleContact>
)

export default Contact
